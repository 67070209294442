const HonorificsData = [
  'Mr',
  'Mrs',
  'Miss',
  'Ms',
  'Mx',
  'Sir',
  'Madam',
  'Professor',
  'Captain',
  'Lady',
  'Lord',
  'Reverend',
  'Judge',
  'Doctor',
  'Master Jedi',
];

export default HonorificsData;
